import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useMediaQuery } from 'react-responsive';
import Button from './button';
import Circle from './circle';
import { getNestedProp } from '../helper/code';
import MarkDownComponent from './markDown';

const responsive = {
  desktop: {
    breakpoint: { max: 8000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 767 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1
  }
};

const CirclesContainer = ({ title, doNotShowTitle, button, circles, circleContainerHeadline, content }) => {
  const [reveal, setReveal] = useState(-1);
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });

  useEffect(() => {
    if (inView) {
      const revealInterval = setInterval(() => {
        setReveal((reveal) => reveal + 1);
        if (reveal > circles?.length) {
          clearInterval(revealInterval);
        }
      }, 500);
    }
  }, [inView]);

  return (
    <Container fluid className="circles-container py-4">
      <div className="circles mb-4">
        <Row>
            {circleContainerHeadline && <Col xs={12} className={'gallery-carousel-topline mb-4'}>
              <h4 className='linebreak'>
                {getNestedProp(circleContainerHeadline)}
              </h4>
            </Col>}
            {!doNotShowTitle && <Col
              xs={12}
              md={8}
              lg={8}
              className={'gallery-carousel-title mb-3'}
            >
              {title && (<h2>{title}</h2>)}
            </Col>}
            {content && (
              <Col
                xs={12}
                md={8}
                lg={8}
                className={'gallery-carousel-body'}
              >
                <MarkDownComponent>{getNestedProp(content)}</MarkDownComponent>
              </Col>
            )}
          </Row>
        <Row
          style={{ minHeight: entry?.target?.offsetHeight || 475 }}
          ref={ref}
        >
          <Carousel
            responsive={responsive}
            arrows={false}
            autoPlay={!isDesktop}
            autoPlaySpeed={5000}
            infinite={!isDesktop}
            containerClass="circles-carousel"
          >
            {circles?.map((circle, index) => {
              return (
                <Col key={index}>
                  {reveal >= index ? <Circle {...circle} /> : ''}
                </Col>
              );
            })}
          </Carousel>
        </Row>
      </div>
      {button && (
        <Col sm="12" md={{ span: 4, offset: 4 }}>
          <Button
            className={'w-100 d-flex justify-content-center'}
            text={button.title}
            {...button}
          />
        </Col>
      )}
    </Container>
  );
};

export default CirclesContainer;
