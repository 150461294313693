import GatsbyImage from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Fade from 'react-reveal';
import { imageDefaultProps, imagePropTypes } from '../propTypes/image';
import Button from './button';
import ColorContainer from './colorContainer';

const TeaserPictureCTA = ({ title, headlineH1, text, image, button, colorContainer: color, article }) => {
  return (
    <Fade left>
      <ColorContainer color={color} className={ !article ? 'py-32' : 'pb-32'}>
        <Container className={ !article ?'teaserPictureCTA my-60' : 'teaserPictureCTA'} fluid>
          <Row>
            <Col className={'teaser-content'} xs={12} md={6}>
              {headlineH1 ? <h1>{title}</h1> : <h2>{title}</h2>}
              <p>{text}</p>
              {button && <Button text={button.title} {...button} />}
            </Col>
            <Col xs={12} md={6} className={'p-0'}>
              <GatsbyImage
                fluid={image.fluid}
                className={'w-100 h-100 teaser-image'}
                alt={image?.description || image?.title}
                fadeIn={false}
              />
            </Col>
          </Row>
        </Container>
      </ColorContainer>
    </Fade>
  );
};

TeaserPictureCTA.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  headlineH1: PropTypes.bool,
  article: PropTypes.bool,
  image: PropTypes.shape(imagePropTypes),
  button: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string
  })
};

TeaserPictureCTA.defaultProps = {
  title: '',
  text: '',
  headlineH1: false,
  image: imageDefaultProps
};

export default TeaserPictureCTA;
