import BackgroundImage from 'gatsby-background-image';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Dropdown, Navbar, Row } from 'react-bootstrap';
import Button from '../../components/button';
import { getLocalizedLink, getTranslation } from '../../helper/translation';
import Icon from '../icon';
import LanguageSwitch from '../languageSwitch';
import { SettingsContext } from '../layout';
import Link from '../link';
import SearchIcon from '../../../static/icons/icon_search.svg';
import SearchOverlay from '../../modules/search/searchOverlay';
import CrossLine from '../../../static/icons/icon_cross_line.svg';

const MobileMenuItem = ({ item }) => {
  const slug = item?.page?.localizedPage ? `/${item?.page?.fullSlug }`: item?.page?.url;
  return (
    <Link to={slug} className={'navbar-item'}>
      {item.name}
      {item?.description && (
        <p className="dropdown-item-text">{item.description}</p>
      )}
    </Link>
  );
};

export const MobileNavigation = () => {
  const settings = useContext(SettingsContext);
  const { node_locale, microcopy, megaMenu } = settings;
  const [navigationOpen, setNavigationOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [history, setHistory] = useState([]);
  const [teaser, setTeaser] = useState(null);
  const [ searchVisible, setSearchVisible ] = useState(false)

  const handleSearchVisibility = () => {
    setSearchVisible(!searchVisible);
  };

  useEffect(() => {
    const teaser = selected?.items?.filter(
      (type) => type?.internal?.type === 'ContentfulMegaMenuPromotion'
    );
    setTeaser(teaser);
  }, [selected]);

  return (
    <>
    <header className="mobile">
      <Container fluid>
        <Navbar expand="md" bg="none">
          <div className="nav-bottom-row">
            <Navbar.Brand href={getLocalizedLink('/', node_locale)}>
              <img
                width="70"
                height="18"              
                src="/images/vier_logo.svg"
                alt="Vier logo"
                className="vier-logo"
              />
            </Navbar.Brand>
            {searchVisible ? <Button
              type={'button'}
              className={'closeBtn'}
              onClick={handleSearchVisibility}
              text={<CrossLine/>}
            /> : (<div className={'d-flex align-items-center'}>
            <Button 
                className={'mobile-search-link'} 
                onClick={() => handleSearchVisibility()} 
                text={
                  <SearchIcon />
                }
              />
              <Dropdown show={navigationOpen} drop={'left'}>
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  onClick={() => setNavigationOpen(!navigationOpen)}
                />
                <Dropdown.Menu>
                  <Dropdown.Header>
                    <div className="dropdown-header-title">
                      {selected ? (
                        <div
                          className={'navbar-selected'}
                        >
                          <Icon name={'thinLeftArrow'}
                            onClick={() => {
                              if (history && history.length > 0) {
                                let tmp = [...history];
                                tmp.splice(history.length - 1, 1);
                                setSelected(history[history.length - 1]);
                                setHistory(tmp);
                              } else {
                                setSelected(null);
                              }
                            }} />
                          {selected.page ? <Link
                            to={
                              selected?.name === 'home' ? '/' : `/${selected?.page?.fullSlug}`
                            }
                          >
                            {selected?.name}
                          </Link> : <h3>{selected?.name}</h3>}
                          <div />
                        </div>
                      ) : (
                        <h3>Startseite</h3>
                      )}
                      <Icon
                        name="crossLine"
                        onClick={() => {
                          setNavigationOpen(false);
                          setSelected(null);
                        }}
                      />
                    </div>
                  </Dropdown.Header>

                  <Row className="container-row ">
                    <div
                      className={selected?.links?.length > 4 ? 'mobile-overflow' : ''}
                    >
                      {selected
                        ? history.length <= 0
                          ? selected.items
                            ?.filter(
                              (type) =>
                                type?.internal?.type !==
                                'ContentfulMegaMenuPromotion'
                            )
                            .map((item) =>
                              selected.items !== null ? (
                                item.internal.type ===
                                  'ContentfulMegaMenuWrapper' ? (
                                  item.megaMenuElements?.map((wrapped) => (
                                    <div
                                      className="dropdown-item-wrapper"
                                      key={wrapped.name}
                                    >
                                      <div
                                        className="dropdown-item-inner-wrapper"
                                        onClick={() => {
                                          setHistory([...history, selected]);
                                          setSelected(wrapped);
                                        }}
                                      >
                                        <Dropdown.Item
                                          className={'navbar-item d-block'}
                                        >
                                          {wrapped.name}
                                        </Dropdown.Item>
                                        <Icon name={'thinRightArrow'} />
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <div
                                    className="dropdown-item-wrapper"
                                    key={item.name}
                                  >
                                    <div
                                      className="dropdown-item-inner-wrapper"
                                      onClick={() => {
                                        setHistory([...history, selected]);
                                        setSelected(item);
                                      }}
                                    >
                                      <Dropdown.Item
                                        className={'navbar-item d-block'}
                                      >
                                        {item.name}
                                      </Dropdown.Item>
                                      <Icon name={'thinRightArrow'} />
                                    </div>
                                  </div>
                                )
                              ) : (
                                <div
                                  className="dropdown-item-wrapper"
                                  key={item.name}
                                >
                                  <div className="dropdown-item-inner-wrapper">
                                    <Link
                                      to={`/${[...history, selected, item]
                                        .map((page) => page?.page?.fullSlug)
                                        .join('/')}`}
                                      className={'navbar-item'}
                                    >
                                      {item.name}
                                    </Link>
                                  </div>
                                </div>
                              )
                            )
                          : selected?.links
                            ?.map((item) => {
                              const { name, title, link, type, internal } = item
                              return internal?.type === 'ContentfulButton' ? (
                                <div
                                  className="dropdown-item-wrapper"
                                  key={name}
                                >
                                  <Button
                                    key={title}
                                    className="secondary"
                                    link={`/${link?.target?.fullSlug}`}
                                    type={type}
                                    text={title}
                                  />
                                </div>
                              ) : (
                                <div
                                  className="dropdown-item-wrapper"
                                  key={name}
                                >
                                  <div className="dropdown-item-inner-wrapper ">
                                    <MobileMenuItem item={item} />
                                  </div>
                                </div>
                              )
                            })
                        : megaMenu
                          ?.filter((item) => item?.page?.localizedPage)
                          ?.filter((item) => item?.name)
                          .map((item, index) => (
                            <>
                              {item.items !== null ? (
                                <div
                                  className="dropdown-item-wrapper"
                                  key={item.name}
                                >
                                  <div className="dropdown-item-inner-wrapper">
                                    <Dropdown.Item
                                      key={item.name}
                                      onClick={() => {
                                        setSelected(item);
                                      }}
                                      className={'navbar-item'}
                                    >
                                      {item.name}
                                    </Dropdown.Item>
                                    <Icon name={'thinRightArrow'} />
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="dropdown-item-wrapper"
                                  key={item.name}
                                >
                                  <div className="dropdown-item-inner-wrapper">
                                    <Link
                                      to={`/${[...history, selected, item]
                                        .map((page) => page?.page?.fullSlug)
                                        .join('/')}`}
                                      className={'d-flex navbar-item'}
                                    >
                                      {item.name}
                                    </Link>
                                  </div>
                                </div>
                              )}
                            </>
                          ))}
                    </div>
                    <div className="mobile-teaser-wrapper">
                      {teaser &&
                        teaser.length > 0 &&
                        teaser
                          .filter((teaser) => teaser?.name)
                          .map((item) => (
                            <div
                              className={`mobile-teaser-container ${teaser && 'px-0'
                                }`}
                            >
                              <BackgroundImage
                                alt={item?.teaser?.title}
                                fixed={item?.teaser?.fixed}
                                style={{
                                  minWidth: '100%',
                                  width: 'unset',
                                  height: '100%',
                                }}
                              >
                                <div className="mobile-teaser-content">
                                  <h4 className="mobile-nav-teaser-title">
                                    {item?.name}
                                  </h4>
                                  <p className="mobile-nav-teaser-description">
                                    {item?.description}
                                  </p>
                                  <Button
                                    type={item?.button?.type}
                                    className={'mobile-nav-teaser-button'}
                                    text={item?.button?.title}
                                    link={item?.button?.link?.target?.fullSlug}
                                  />
                                </div>
                              </BackgroundImage>
                            </div>
                          ))}

                      <div className="mobile-nav-teaser">
                        <div className="mobile-nav-teaser-wrapper">
                          <p>
                            {getTranslation('contactCenter.content', microcopy)}
                          </p>
                          <a
                            href={`tel:${getTranslation(
                              'contactCenter.phoneNumber',
                              microcopy
                            )}`}
                          >
                            <Button
                              className={'contact-center-call-button'}
                              text={
                                <>
                                  <Icon name={'phoneLine'} />
                                  {getTranslation(
                                    'contactCenter.call',
                                    microcopy
                                  )}
                                </>
                              }
                              type={'secondary'}
                            />
                          </a>
                          <a
                            href={`mailto:${getTranslation(
                              'contactCenter.mailAddress',
                              microcopy
                            )}`}
                          >
                            <Button
                              className={'contact-center-mail-button'}
                              text={
                                <>
                                  <Icon name={'mail'} className={'mr-2'} />
                                  {getTranslation(
                                    'contactCenter.mail',
                                    microcopy
                                  )}
                                </>
                              }
                              type={'secondary'}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="mobile-nav-footer">
                        <Row className="mobile-nav-inner-wrapper">
                          <Col sm={6}>
                            <Link
                              className="dropdown-login-link"
                              to={{
                                url: getLocalizedLink(
                                  getTranslation('login.link', microcopy),
                                  node_locale
                                )
                              }}
                            >
                              {getTranslation('login.text', microcopy)}
                              <Icon name={'thinRightArrow'} />
                            </Link>
                          </Col>
                          <Col sm={6}>
                            <div className="mobile-nav-language-switch">
                              <LanguageSwitch />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Row>
                </Dropdown.Menu>
              </Dropdown>
            </div>)}
          </div>
        </Navbar>
      </Container>
    </header>
    {searchVisible && (
      <SearchOverlay onClose={() => setSearchVisible(false)} />
    )}
    </>
  );
};
