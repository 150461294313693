import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Col } from 'react-bootstrap';
import Button from '../../../components/button';
import { SettingsContext } from '../../layout';
import ItemLink from './itemLink';
import MenuLink from './menuLink';

const MenuElement = ({ item, column }) => {
  const settings = useContext(SettingsContext);
  const { node_locale, isSearchPage } = settings;
  return (
    <Col
      key={item?.name}
      md={column ? 12 : 4}
      xl={column ? 12 : item.doubleColumn ? 6 : 3}
      className={`nav-menu-content`}
    >
      <div className="nav-menu-content-inner-wrapper">
        {item?.page ? (
          <>
            {item?.page?.localizedPage ? (
              <MenuLink
                item={item}
                locale={node_locale}
              />
            ) : null}
          </>
        ) : (
          <p className="nav-menu-title">
            {item?.icon && (
              <img
                className="mr-2"
                src={item?.icon?.file?.url}
                alt={item?.icon?.description || item?.icon?.title}
              />
            )}
            {item?.name}
          </p>
        )}
        <div className={item.doubleColumn ? 'nav-items-link-container' : ''}>
          {item?.links?.map((itemLink) => { 
            const { name, description, title, link, type, internal, page } = itemLink;
            const target = isSearchPage ? (page?.fullSlug || page?.url || link.target?.fullSlug) : link?.target?.fullSlug
            return internal?.type === 'ContentfulMegaMenuLink' ? (
              <ItemLink
                name={name}
                page={page}
                description={description}
                item={item}
              />
            ) : (
              <Button
                key={title}
                className="nav-menu-button"
                link={`/${target}`}  
                type={type}
                text={title}
              />
            );
          })}
        </div>
      </div>
    </Col>
  );
};

MenuElement.propTypes = {
  item: PropTypes.object,
  column: PropTypes.bool
};
MenuElement.defaultProps = {};

export default MenuElement;
