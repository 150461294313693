import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { SettingsContext } from '../modules/layout';

const MarketingCookie = () => {
    const { marketingCookies  } = useContext(SettingsContext);

    return (
        marketingCookies && <Helmet>
            <script async type="text/javascript">
                {`${marketingCookies.marketingCookies}`}
            </script>
        </Helmet>
    )
}

export default MarketingCookie