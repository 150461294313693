import GatsbyImage from "gatsby-image";
import PropTypes from "prop-types";
import React from "react";
import { getNestedProp } from "../helper/code";
import Link from "../modules/link";
import { imageDefaultProps, imagePropTypes } from "../propTypes/image";
import MarkDownComponent from './markDown'

const CarouselItem = ({ image, title, caption, content, link, imageFullscreen, displayJustImage, display1Column }) => {
    return (
        <div className={"gallery-carousel-item carousel-type-" + (imageFullscreen ? (displayJustImage && display1Column) ? 'just-image' : 'image' : 'icon')}>
            <div className={`carousel-item-image ${!image ? 'no-image' : ''}`}>
                {image && (
                    <Wrapper condition={link} wrapper={children => <Link to={link}>{children}</Link>}>
                        <img src={image?.file?.url} alt={image?.description || image?.title}></img>
                    </Wrapper>
                )}
                {
                    (displayJustImage && caption) && (
                      <div className="gallery-image-caption">
                        <p>{caption}</p>
                      </div>
                    )
                }
            </div>
            {
                !displayJustImage && (
                <div className="gallery-carousel-item-text">
                    <h5 className="mb-3 mt-4">{title}</h5>
                    <div className="mb-3">
                        <MarkDownComponent>{getNestedProp(content)}</MarkDownComponent>
                    </div>
                    {link &&
                        <Link to={link}>{link.title}</Link>
                    }
                </div>
                )
            }
        </div>
    )
}

const Wrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

CarouselItem.propTypes = {
    image: PropTypes.shape(imagePropTypes),
    title: PropTypes.string,
    content: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    link: PropTypes.object,
    imageFullscreen: PropTypes.bool,
    displayJustImage: PropTypes.bool,
    display1Column: PropTypes.bool
}
CarouselItem.defaultProps = {
    title: "",
    content: {},
    imageFullscreen: true
}

export default CarouselItem
