import PropTypes from "prop-types";
import React from 'react';
import { Col } from 'react-bootstrap';
import MenuElement from './menuElement';

const MenuWrapper = ({ item }) => {
    return (
        <Col
            key={item?.name}
            md={4}
            xl={3}
            className="columnWrapper"
        >
            {item?.megaMenuElements?.map((item) =>
                <MenuElement
                    item={item}
                    column={true}
                />
            )}
        </Col>
    )
}

MenuWrapper.propTypes = {
    item: PropTypes.object,
};
MenuWrapper.defaultProps = {
};

export default MenuWrapper