import React from 'react';
import { DesktopNavigation } from './DesktopNavigation';
import { MobileNavigation } from './MobileNavigation';

const Navigation = () => {
  return (
    <>
      <DesktopNavigation />
      <MobileNavigation />
    </>
  );
};

export default Navigation;
