import PropTypes from 'prop-types';
import React from 'react';
import Link from '../../link';

const ItemLink = ({ item, name, description, page }) => {
  return page ? (
    <Link
      key={name}
      to={page.localizedPage ? { target: { ...page, slug: page.fullSlug } } : { url: page.url}}
      className={
        item.doubleColumn
          ? 'nav-item-link-container twoColumns'
          : 'nav-item-link-container'
      }
    >
      <p className="nav-item-link">{name}</p>
      <p className="nav-item-text">{description}</p>
    </Link>
  ) : null;
};

ItemLink.propTypes = {
  page: PropTypes.object,
  locale: PropTypes.any,
  name: PropTypes.string,
  description: PropTypes.string,
};
ItemLink.defaultProps = {};

export default ItemLink;
