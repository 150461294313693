import React from 'react'

export const LinkRenderer = (node) => {
  return (
    <a href={node.href} 
       className={node.class} 
       target={`${isInternalLink(node.href) ? '_self' : '_blank'}`} 
       rel={`${isInternalLink(node.href) ? '' : 'noopener noreferrer'}`}
    >
      {node.children}
    </a>
  );
}

export function isInternalLink(uri) {
  const domain = 'vier.ai' 
  try {
    const { hostname } = new URL(uri)
    return hostname.includes(domain)
  } catch (error) {
    return true
  }
}

export function addAnchorToInternalLink(linkEntry, url) {
  if (typeof linkEntry === 'string' || !linkEntry.anchor) {
    return url
  }
  return `${url}#${linkEntry.anchor}`
}