/* Global imports */
import PropTypes from "prop-types";
import React from "react";

/* Local imports */

/* Component definition */
const Input = ({
  className,
  id,
  name,
  value,
  placeholder,
  type,
  hintText,
  errorText,
  warningText,
  onChange,
  disabled,
}) => {
  const getModifier = () => {
    if (disabled) {
      return "input--disabled";
    }
    if (errorText) {
      return "input--error";
    } else if (warningText) {
      return "input--warning";
    }
    return "";
  };
  const getMessage = () => {
    return errorText || warningText || hintText || "";
  };

  return (
    <>
      <input
        type={type}
        name={name}
        id={id}
        className={`input ${getModifier()} ${className}`}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
      />
      <span className="message">{getMessage()}</span>
    </>
  );
};
/* PropTypes */
Input.propTypes = {
  value: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(["text", "password", "email"]),
  hintText: PropTypes.string,
  errorText: PropTypes.string,
  warningText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
Input.defaultProps = {
  className: "",
  type: "text",
  disabled: false,
  value: "",
};

/* Local utility functions */

/* Styles */

export default Input;
