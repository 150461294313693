import GatsbyImage from 'gatsby-image';
import getYouTubeID from 'get-youtube-id';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import YouTube from 'react-youtube';
import { getNestedProp } from '../helper/code';
import { cookieExists, setCookie } from '../helper/cookie';
import { getTranslation } from '../helper/translation';
import { SettingsContext } from '../modules/layout';
import Button from './button';
import ColorContainer from './colorContainer';
import { imagePropTypes } from '../propTypes/image';
import BackgroundImage from 'gatsby-background-image';
import MarkDownComponent from './markDown';

const opts = {
  width: '100%',
  height: '100%'
};

const TextVideo = ({ title, doNotShowTitle, headline, content, items, colorContainer: color }) => {
  const [consent, setConsent] = useState(false);
  const isMore = items?.length > 1;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setConsent(cookieExists('youtubeConsent'));
    }
  });

  const setYoutubeConsent = () => {
    setCookie('youtubeConsent');
    setConsent(true);
  };

  return (
    <ColorContainer color={color} className={'py-32'}>
      <SettingsContext.Consumer>
        {(settings) => {
          const { microcopy } = settings;
          return (
            <div className={`video-text-container`}>
              <Container fluid className="video-text">
                {headline && (
                  <h4 className="mb-4 textVideo-headline">
                    {headline}
                  </h4>
                )}
                {(title && !doNotShowTitle) && (<h2>{title}</h2>)}
                <div>
                  <Row>
                    <Col lg={isMore ? 6 : 12}>
                      <MarkDownComponent>{getNestedProp(content)}</MarkDownComponent>
                    </Col>
                  </Row>
                  <Row>
                    {items &&
                      items.map((item, index) => {
                        const orientation = item?.orientation?.toString();
                        const content = getNestedProp(item?.content);
                        return (
                          <React.Fragment key={index}>
                            <Col lg={isMore ? 6 : 12}>
                              <Row
                                className={`${
                                  orientation !== 'right'
                                    ? 'flex-row-reverse'
                                    : ''
                                }`}
                              >
                                <Col
                                  md={{
                                    span: isMore ? 12 : 6,
                                    order: isMore ? 2 : 1
                                  }}
                                  lg={{ span: isMore ? 12 : 6 }}
                                  className="video-text-text"
                                  key={index}
                                >
                                  {!item.doNotShowTitle && <h3 className="mb-4 d-md-block d-none">
                                    {item.title}
                                  </h3>}
                                  <div className="mb-4 d-md-block d-none">
                                    <MarkDownComponent>{getNestedProp(content)}</MarkDownComponent>
                                  </div>
                                  {item.button && (
                                    <Button
                                      className={
                                        'd-md-inline-block d-none mb-5'
                                      }
                                      text={item.button.title}
                                      {...item.button}
                                    />
                                  )}
                                </Col>
                                <Col
                                  md={{
                                    span: isMore ? 12 : 6,
                                    order: isMore ? 1 : 2
                                  }}
                                  lg={{ span: isMore ? 12 : 6 }}
                                  className={isMore ? 'mb-4' : ''}
                                >
                                  {item.video ? (
                                    consent ? (
                                      <YouTube
                                        videoId={getYouTubeID(item.video)}
                                        opts={opts}
                                        className="position-absolute w-100"
                                        containerClassName="youtube-container position-relative"
                                      />
                                    ) : item?.videoConsentImage ? (
                                      <BackgroundImage
                                        className={`video-consent h-100`}
                                        fixed={item?.videoConsentImage?.fixed}
                                      >
                                        <div
                                          className={`overlay-container d-flex flex-column justify-content-center align-items-center h-100 w-100`}
                                        >
                                          <div class="overlay"></div>
                                          <p>
                                            {getTranslation(
                                              'youtube.noConsent',
                                              microcopy
                                            )}
                                          </p>
                                          <Button
                                            text={getTranslation(
                                              'youtube.setConsent',
                                              microcopy
                                            )}
                                            onClick={() => setYoutubeConsent()}
                                          />
                                        </div>
                                      </BackgroundImage>
                                    ) : (
                                      <div className="no-consent d-flex flex-column justify-content-center align-items-center h-100 w-100">
                                        <p>
                                          {getTranslation(
                                            'youtube.noConsent',
                                            microcopy
                                          )}
                                        </p>
                                        <Button
                                          text={getTranslation(
                                            'youtube.setConsent',
                                            microcopy
                                          )}
                                          onClick={() => setYoutubeConsent()}
                                        />
                                      </div>
                                    )
                                  ) : (
                                    <>
                                      {item.image && (
                                        <GatsbyImage
                                          fluid={item.image.fluid}
                                          alt={
                                            item?.image?.description ||
                                            item?.image?.title
                                          }
                                        />
                                      )}
                                    </>
                                  )}
                                </Col>
                                <Col
                                  md={{ span: 12, order: 3 }}
                                  className="d-md-none mb-5 mt-3"
                                >
                                  {!item.doNotShowTitle && 
                                  <h3 className="mb-4 d-md-none">
                                    {item.title && item.title}
                                  </h3>}
                                  <div className="mb-5">
                                    <MarkDownComponent>{getNestedProp(content)}</MarkDownComponent>
                                  </div>
                                  {item.button && (
                                    <Button
                                      className={'mb-5 d-inline-block'}
                                      text={item.button.title}
                                      {...item.button}
                                    />
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          </React.Fragment>
                        );
                      })}
                  </Row>
                </div>
              </Container>
            </div>
          );
        }}
      </SettingsContext.Consumer>
    </ColorContainer>
  );
};

TextVideo.propTypes = {
  title: PropTypes.string,
  headline: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      button: PropTypes.object,
      video: PropTypes.string,
      orientation: PropTypes.arrayOf(PropTypes.string),
      image: PropTypes.shape(imagePropTypes)
    })
  ),
  color: PropTypes.object
};

TextVideo.defaultProps = {
  title: '',
  headline: '',
  items: [],
  color: {}
};

export default TextVideo;
