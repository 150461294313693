import React, { useContext, useEffect, useRef, useState } from 'react';
import CrossLine from '../../../static/icons/icon_cross_line.svg';
import SearchIcon from '../../../static/icons/icon_search.svg';
import Button from '../../components/button';
import Debounce from '../../helper/debounce';
import { SettingsContext } from '../layout';

const SearchBar = ({
  initSearchTerm,
  placeholder,
  onChange,
  onReset,
  className,
  autoFocus,
  onBackdropClick,
  disableActiveBackdropStyles,
  small,
  store,
}) => {
  const inputRef = useRef();
  const [isActive, setIsActive] = useState(false);
  const [autoSuggestion, setAutoSuggestion] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = Debounce(searchTerm, 500);
  const { node_locale } = useContext(SettingsContext);

  const [totalSearchResult, setTotalSearchResult] = useState(0);
  const maxSearchItemsShow = 10;

  const getAutoSuggestion = async (searchTerm) => {
    var result = Object.values(store)
      .filter((page) => page.node_locale === node_locale)
      .filter(
        (f) =>
          f.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          f.content.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => {
        return (
          b.title.toLowerCase().includes(searchTerm) -
          a.title.toLowerCase().includes(searchTerm)
        );
      });
    const totalResults = result.length;
    setTotalSearchResult(totalResults);

    if (totalResults > maxSearchItemsShow) {
      result = result.slice(0, maxSearchItemsShow);
    }
    return result;
  };

  useEffect(() => {
    if (searchTerm?.length >= 3 && debouncedSearchTerm) {
      getAutoSuggestion(debouncedSearchTerm.toLowerCase()).then((res) => {
        setAutoSuggestion(res);
      });
    } else {
      setAutoSuggestion([]);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setSearchTerm(initSearchTerm);
  }, [initSearchTerm]);

  const reset = () => {
    setIsActive(false);
    setSearchTerm('');
    onReset();
  };

  const cleanInput = () => {
    setSearchTerm('');
    setSearchText('');
    inputRef.current.focus();
  };

  const change = (e) => {
    setSearchTerm(e.target.value);
  };

  const setSearchText = (text) => {
    if (text) {
      setIsActive(false);
      onChange(`${text}`);
    }
  };

  const keyPressHandler = (e) => {
    if (e.key === 'Enter') {
      setSearchText(searchTerm);
      inputRef.current.blur();
    }
  };

  const selectSuggestion = (item) => {
    setSearchTerm(item);
    setSearchText(item);
  };

  const getFormattedSuggestion = (text, term) => {
    const re = new RegExp(decodeURIComponent(term), 'gi');
    return text.replace(re, (match) => `<b>${match}</b>`);
  };

  const handleBackDropClick = () => {
    setIsActive(false);
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  return (
    <div>
      <div
        className={`search ${small ? 'small' : ''}  ${
          isActive ? 'isActive' : ''
        }`}
      >
        <div className={'searchField'}>
          <input
            type="search"
            name="search_field"
            placeholder={placeholder}
            className={`searchInput ${className}`}
            onChange={change}
            onFocus={() => setIsActive(true)}
            onKeyPress={keyPressHandler}
            value={searchTerm}
            autoComplete="off"
            ref={inputRef}
            autoFocus={autoFocus}
          />
          {searchTerm && (
            <Button
              type={'button'}
              onClick={cleanInput}
              className={'closeBtn'}
              text={<CrossLine />}
            />
          )}
          {(isActive || !searchTerm) && (
            <Button
              type={'button'}
              className={'searchBtn'}
              onClick={() => setSearchText(searchTerm)}
              text={<SearchIcon />}
            />
          )}
          {!isActive && searchTerm && (
            <Button
              type={'button'}
              className={'searchBtn'}
              onClick={reset}
              text={<CrossLine />}
            />
          )}
        </div>

        {autoSuggestion && autoSuggestion.length > 0 && (
          <div className={'autoSuggestion'}>
            {autoSuggestion.map((item, index) => (
              <div
                className={'autoSuggestionContent'}
                key={index}
                onClick={() => selectSuggestion(item.title)}
                dangerouslySetInnerHTML={{
                  __html: getFormattedSuggestion(item.title, searchTerm)
                }}
              ></div>
            ))}
            {totalSearchResult > maxSearchItemsShow && (
              <div
                className={'autoSuggestionContent showAll'}
                key={maxSearchItemsShow}
                onClick={() => selectSuggestion(searchTerm)}
                dangerouslySetInnerHTML={{
                  __html: `Show All Results (${totalSearchResult})`
                }}
              ></div>
            )}
          </div>
        )}
      </div>
      <div
        className={`backdrop ${
          isActive && !disableActiveBackdropStyles ? 'isActive' : ''
        }`}
        onClick={handleBackDropClick}
      />
    </div>
  );
};

export default SearchBar;
