import PropTypes from 'prop-types';
import React from 'react';

const ColorContainer = ({className, color, children }) => {
  return color ? (
    <div className={`bg-${color?.brightness.toString()}_${color?.baseColor.toString()} ${className} `}>
      {children}
    </div>
  ) : <>{children} </>;
};

ColorContainer.propTypes = {
  className: PropTypes.string,
  color: PropTypes.shape({
    baseColor: PropTypes.string,
    brightness: PropTypes.string
  })
};

ColorContainer.defaultProps = {
  className: '',
  color: {
    baseColor: '',
    brightness: ''
  }
};

export default ColorContainer;
