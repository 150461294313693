import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { SettingsContext } from './layout';

const LanguageSwitch = () => {
  const { node_locale, isSearchPage, translatedPageSlug } =
    useContext(SettingsContext);
  const locales = ['de', 'en'];
  const [isClient, setIsClient] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);
  const isDesktop = useMediaQuery({ minWidth: 765 });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsClient(true);
      if (isSearchPage) {
        const urlParams = new URLSearchParams(window.location.search);
        setSearchTerm(urlParams.get('find'))
      }
    }
  }, []);

  const getLocalizedCurrentLink = (targetLocale) => {
    if (isSearchPage) {
      return `${targetLocale === 'de' ? `/search?find=${searchTerm}` : `/en/search?find=${searchTerm}`}`
    } else if (!translatedPageSlug) {
      return `${targetLocale == 'de' ? '/' : '/en/'}`
    } else {
      return `${targetLocale == 'de' ? '/' : '/en/'}${translatedPageSlug}`
    }
  };

  return (
    isClient &&
    (isDesktop ? (
      <Dropdown className={`language-select`} drop={'down'}>
        <Dropdown.Toggle className={'text-uppercase'}>
          {node_locale}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {locales
            .filter((lang) => lang !== node_locale)
            .map((lang) => (
              <Dropdown.Item
                key={lang}
                href={getLocalizedCurrentLink(lang)}
                className={'text-uppercase'}
              >
                {lang}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    ) : (
      locales.map((lang) => (
        <a
          className={`dropdown-language-link ${
            lang === node_locale ? 'active' : ''
          }`}
          href={getLocalizedCurrentLink(lang)}
        >
          {lang}
        </a>
      ))
    ))
  );
};

export default LanguageSwitch;
