import PropTypes from 'prop-types';

export const imageDefaultProps = {
  title: '',
  text: '',
  fluid: {},
  fixed: {}
};

export const imagePropTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  fluid: PropTypes.object,
  fixed: PropTypes.object
};
