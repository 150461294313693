import { graphql, useStaticQuery } from 'gatsby';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { getTranslation } from '../../helper/translation';
import { SettingsContext } from '../../modules/layout';
import SearchBar from '../search/searchBar';

const SearchOverlay = ({ onClose }) => {
  const [searchTerm, setSearchTerm] = useState(null);
  const { microcopy, node_locale } = useContext(SettingsContext);
  const [store, setStore] = useState(null);
  const [loading, setLoading] = useState(true);
  const {
    current: {
      localSearchPages: { publicStoreURL }
    }
  } = useRef(
    useStaticQuery(graphql`
      {
        localSearchPages {
          publicStoreURL
        }
      }
    `)
  );

  useEffect(() => {
    const fetchStore = () => fetch(publicStoreURL)
       .then((res) => res.json())
       .then((store) => {
        setStore(store)
        setLoading(false)
       })

   fetchStore()
  }, []);

  useEffect(() => {
    if (searchTerm) {
      window.location = `${
        node_locale === 'en' ? '/en' : ''
      }/search?find=${encodeURIComponent(searchTerm)}`;
    }
  }, [searchTerm]);

  return (
    <Container>
      <div className="searchContainer">
        {!loading && (
          <SearchBar
            initSearchTerm={searchTerm}
            store={store}
            onChange={(val) => setSearchTerm(val)}
            onReset={() => setSearchTerm(null)}
            placeholder={getTranslation('search.placeHolder', microcopy)}
            autoFocus
            disableActiveBackdropStyles
            onBackdropClick={onClose}
            small
          />
        )}
      </div>
      <div className="searchOverlay" onClick={onClose} />
    </Container>
  );
};

export default SearchOverlay;
