import PropTypes from 'prop-types';
import React from 'react';
import CountUp from 'react-countup';

const Circle = ({ symbol, number, title, isPercent }) => {
  return (
    <div className="circle">
      <div className="circle-container">
        <div className="circle-inner">
          <div className="counter-numbers">
            <CountUp
              start={0}
              end={number}
              duration={1.5}
              separator="."
              prefix={symbol ?? ''}
              suffix={isPercent ? ' %' : ''}
              delay={1.65}
            />
          </div>
          <div className="counter-titles">{title}</div>
        </div>
      </div>

      <div className="circle-mask">
        <div className="circle-left">
          <div className="pie spinner"></div>
        </div>
        <div className="circle-right">
          <div className="pie spinner"></div>
        </div>
      </div>
    </div>
  );
};

Circle.propTypes = {
  symbol: PropTypes.oneOf(['-', '+']),
  number: PropTypes.number,
  text: PropTypes.string,
  isPercent: PropTypes.bool
};
Circle.defaultProps = {
  symbol: '',
  number: 25,
  text: 'FIRST CALL RESOLVE RATE',
  isPercent: true
};

export default Circle;
