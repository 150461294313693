import BackgroundImage from "gatsby-background-image";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from 'react-bootstrap';
import Link from "../modules/link";
import Button from "./button";
import { getNestedProp } from '../helper/code';
import MarkDownComponent from './markDown';

const FloatingContentColumn = (column, index) => (
    <Col
        className={"mb-5 pb-4"}
        xs={12}
        sm={6}
        md={3}
        key={index}
    >
        <div className={"d-flex flex-column h-100"}>
            <div className={"floatingText"}>
                <h3 className="breakWord">{column.title}</h3>
                <MarkDownComponent>{getNestedProp(column.content)}</MarkDownComponent>
            </div>
            {column.link && (
                <div className="floatingLink">
                    <Link to={column.link}>
                        {column.link.title}
                    </Link>
                </div>
            )}
        </div>
    </Col>
)

const FloatingContentComponent = ({ headline, textContent, backgroundImage, title, topRow, bottomRow, links }) => {
    const Wrapper = backgroundImage ? BackgroundImage : Container;
    const fluid = backgroundImage ? backgroundImage.fluid : true;

    return (
        <Wrapper fluid={fluid} className={'floating-background py-60'} style={{ backgroundPosition: 'top right' }}>
            <div className={'floating-background-top-row'}>
                <Container fluid>
                    <Row>
                        <Col className={"sm-mb-5 mb-80"} sm={{ span: 9 }}>
                            <Row>
                                <Col md={{ span: 12 }} className={"mb-2"}>
                                    <h4>{headline}</h4>
                                </Col>
                                <Col md={{ span: 12 }} className={"mb-2"}>
                                    <h2>{title}</h2>
                                </Col>
                                <Col md={{ span: 12 }}>
                                    <MarkDownComponent>{getNestedProp(textContent)}</MarkDownComponent>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={0} md={3}></Col>
                        {topRow?.map((column, index) => <FloatingContentColumn key={index} {...column} />)}
                    </Row>
                </Container>
            </div>
            <div className={'floating-background-bottom-row pb-0 pb-md-120'}>
                <Container fluid>
                    <Row className={'d-flex justify-content-end'}>
                        {bottomRow?.map((column, index) => (<FloatingContentColumn key={index} {...column} />))}
                    </Row>
                    <Row>
                        {links && links.map((link, index) => (

                            <Col
                                key={index}
                                xs={12}
                                sm={6}
                                md={{ span: index === 1 ? 6 : 3, offset: index === 0 ? 3 : 0 }}
                            >
                                <Button
                                    className={'w-100 mb-4 d-flex justify-content-center'}
                                    text={link.title}
                                    {...link}
                                />
                            </Col>

                        ))}
                    </Row>
                </Container>

            </div>
        </Wrapper>
    )

}

FloatingContentComponent.propTypes = {
    title: PropTypes.string,
    textContent: PropTypes.shape({
        subtitle: PropTypes.string
    }),
    backgroundImage: PropTypes.shape({
        fluid: PropTypes.object
    }),
    headline: PropTypes.string,
    topRow: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            content: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.shape({
                    content: PropTypes.string
                })
            ]),
        })
    ),
    bottomRow: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            content: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.shape({
                    content: PropTypes.string
                })
            ]),
        })
    ),
    links: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        type: PropTypes.string
    }))
}
FloatingContentComponent.defaultProps = {
    title: "",
    textContent: {
        subtitle: ""
    },
    headline: "",
    backgroundImage: null,
    topRow: [],
    bottomRow: [],
    links: []
}

export default FloatingContentComponent
