import PropTypes from 'prop-types';
import React from 'react';
import { Container, Row } from 'react-bootstrap';
import MenuElement from './menuElement';
import MenuPromotion from './menuPromotion';
import MenuWrapper from './menuWrapper';


const COLUMN_TYPES = {
  ContentfulMegaMenuPromotion: MenuPromotion,
  ContentfulMegaMenuElement: MenuElement,
  ContentfulMegaMenuWrapper: MenuWrapper
}

const renderNavColumn = (props) => {
  const NavColumn = COLUMN_TYPES[props.item?.internal?.type]
  return NavColumn ? (
    <NavColumn {...props} />
  ) : null
}

export const NavMenu = ({ items }) => {
  return (
    <Container className={`nav-menu`}>
      <Container fluid>
        <Row style={{ width: "100%" }}>
          {
            items?.map(item => renderNavColumn({item}))
            }
        </Row>
      </Container>
    </Container>
  );
};

NavMenu.propTypes = {
  firstLevelSlug: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      icon: PropTypes.object,
      internal: PropTypes.shape({
        type: PropTypes.string
      }),
      page: PropTypes.shape({
        slug: PropTypes.string,
        id: PropTypes.string
      }),
      links: PropTypes.array
    })
  )
};

NavMenu.defaultProps = {
  firstLevelSlug: '',
  items: []
};