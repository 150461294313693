import PropTypes from "prop-types";
import React from "react";
import ArrowLeft from '../../static/icons/icon_arrow_left.svg';
import ArrowLineDown from '../../static/icons/icon_arrow_line_down.svg';
import ArrowLineLeft from '../../static/icons/icon_arrow_line_left.svg';
import ArrowLineRight from '../../static/icons/icon_arrow_line_right.svg';
import ArrowRight from '../../static/icons/icon_arrow_right.svg';
import ThinLeftArrow from '../../static/icons/icon_arrow_thin_left.svg';
import ThinRightArrow from "../../static/icons/icon_arrow_thin_right.svg";
import Bars from '../../static/icons/icon_bars.svg';
import Chat from '../../static/icons/icon_chat.svg';
import ChatLine from '../../static/icons/icon_chat_line.svg';
import CircleArrowLeft from '../../static/icons/icon_circle_arrow_left.svg';
import CircleArrowRight from '../../static/icons/icon_circle_arrow_right.svg';
import CrossLine from '../../static/icons/icon_cross_line.svg';
import Dropdown from '../../static/icons/icon_dropdown.svg';
import Home from '../../static/icons/icon_home.svg';
import Info from '../../static/icons/icon_info.svg';
import Mail from '../../static/icons/icon_mail.svg';
import MailLine from '../../static/icons/icon_mail_line.svg';
import Phone from '../../static/icons/icon_phone.svg';
import PhoneLine from '../../static/icons/icon_phone_line.svg';
import Search from '../../static/icons/icon_search.svg';
import Share from '../../static/icons/icon_share.svg';
import Talk from '../../static/icons/icon_talk.svg';
import Float from '../../static/icons/icon_float.svg';



const Icon = ({ name, className, hover, onClick }) => {
    const icons = {
        'arrowLeft': ArrowLeft,
        'arrowLineDown': ArrowLineDown,
        'arrowLineLeft': ArrowLineLeft,
        'arrowLineRight': ArrowLineRight,
        'arrowRight': ArrowRight,
        'bars': Bars,
        'circleArrowLeft': CircleArrowLeft,
        'circleArrowRight': CircleArrowRight,
        'crossLine': CrossLine,
        'dropdown': Dropdown,
        'info': Info,
        'search': Search,
        'chat': Chat,
        'home': Home,
        'mail': Mail,
        'phone': Phone,
        'talk': Talk,
        'chatLine': ChatLine,
        'phoneLine': PhoneLine,
        'mailLine': MailLine,
        'share': Share,
        "thinRightArrow": ThinRightArrow,
        "thinLeftArrow" : ThinLeftArrow,
        "float" : Float
    }

    if (!icons[name])
        return '';

    const DynamicIcon = icons[name];


    return (
        <span
            className={`icon ${className} ${hover ? 'hover' : ''}`}
            onClick={onClick}
        >
            <DynamicIcon/>
        </span>
    )
}

Icon.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    hover: PropTypes.bool,
    onClick: PropTypes.func
}

Icon.defaultProps = {
    name: '',
    className: '',
    hover: false,
    onClick: () => {},
}

export default Icon