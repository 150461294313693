import React from 'react'
import ReactMarkdown from 'react-markdown'
import { LinkRenderer } from "../helper/linkRenderer";

const MarkDownComponent = ({ children }) => {
  const childrenWithLineBreaks = typeof children === "string" && children.replaceAll(/\n/g, '\n&nbsp;  \n  ')

  return (
    <ReactMarkdown className='mark-down' components={{ a: LinkRenderer}}>{ childrenWithLineBreaks }</ReactMarkdown>
  )
}

export default MarkDownComponent