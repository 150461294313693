import BackgroundImage from "gatsby-background-image";
import GatsbyImage from "gatsby-image";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import MarkDownComponent from './markDown';
import { getNestedProp } from '../helper/code';
import { imageDefaultProps, imagePropTypes } from "../propTypes/image";
import Button from './button';

const Video = ({ className, source, children }) => {
    return (
        <div className={className}>
            <video className={`hero-video p-0`} loop autoPlay muted>
                <source src={source} type="video/mp4" />
                <source src={source} type="video/ogg" />
                Your browser does not support the video tag.
            </video>
            {children}
        </div>
    )
}

const HeroComponent = ({ title, titleHighlight, subtitle, image, button, overlap }) => {
    const isVideo = image?.file?.contentType?.indexOf('video/') > -1;
    const Wrapper = image ? (isVideo ? Video : BackgroundImage) : Container;
    const [showSubtitle, setShowSubtitle] = useState(false);
    const titleRef = useRef();
    const isDesktop = useMediaQuery({ minWidth: 768 });

    useEffect(() => {
        if (titleRef.current) {
            const { offsetHeight } = titleRef.current;
            const titleLineHeight = isDesktop ? 104 : 48;
            setShowSubtitle((offsetHeight / titleLineHeight) <= 2)
        }
    }, [])

    return (
        <>
            <GatsbyImage
                className={'d-flex d-sm-none'}
                fluid={image?.fluid}
                alt={image?.description || image?.title}
                fadeIn="false"
            />
            <Wrapper
                className={`hero-component ${!image ? 'hero-animation' : ''} ${overlap ? 'overlap' : ''}`}
                fluid={image ? image.fluid : true}
                source={image?.file?.url}
                fadeIn="false"
            >
                <Container
                    className={'hero-content pl-0'}
                    fluid
                >
                    <Row
                        className={'w-100'}>
                        <Col
                            xs={12}
                            lg={8}
                            xl={9}
                        >
                            <h1
                                className={'ml-lg-0 mb-lg-4'}
                                ref={titleRef}
                            >
                                {title}
                                {titleHighlight && (
                                    <>
                                        <br />
                                        <span className={'highlight'}>
                                            {titleHighlight}
                                        </span>
                                    </>
                                )}
                            </h1>
                            {
                                showSubtitle && (
                                    <div
                                        className={'m-0 mb-4'}
                                    >
                                        <MarkDownComponent>{getNestedProp(subtitle)}</MarkDownComponent>
                                    </div>
                                )
                            }
                        </Col>
                        <Col
                            xs={12}
                            lg={4}
                            xl={3}
                            className={'d-flex align-items-end'}
                        >
                            <Button
                                text={button.title}
                                {...button}
                                className={'w-100'}
                            />
                        </Col>
                    </Row>
                </Container>
            </Wrapper>
        </>
    )
}

HeroComponent.propTypes = {
    title: PropTypes.string,
    titleHighlight: PropTypes.string,
    subtitle: PropTypes.oneOfType(
        [
            PropTypes.shape({
                subtitle: PropTypes.string
            }),
            PropTypes.string
        ]
    ),
    image: PropTypes.shape(imagePropTypes),
    button: PropTypes.object,
    overlap: PropTypes.bool
}

HeroComponent.defaultProps = {
    title: "",
    titleHighlight: "",
    subtitle: {
        subtitle: ""
    },
    image: imageDefaultProps,
    button: {},
    overlap: true
}
export default HeroComponent