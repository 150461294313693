import PropTypes from "prop-types";
import React from "react";
import Link from "../modules/link";

const Button = ({ className, text, onClick, type, disabled, link, target }) => {
    return link ? (
        <Link
            to={link}
            target={target}
            className={`button button--${type} ${className} ${link ? "button--link" : ""
                }`}
        >
            {text}
        </Link>
    ) : (
        <button
            className={`button button--${type} ${className}`}
            onClick={onClick}
            type={'button'}
            name={text}
            disabled={disabled}
        >
            {text}
        </button>
    );

}

Button.propTypes = {
    className: PropTypes.string,
    text: PropTypes.any.isRequired,
    onClick: PropTypes.func,
    type: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
    disabled: PropTypes.bool,
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    target: PropTypes.string,
};
Button.defaultProps = {
    className: "",
    type: "primary",
    disabled: false,
};

export default Button;
