import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { imageDefaultProps, imagePropTypes } from "../propTypes/image";
import GatsbyImage from "gatsby-image"
import { Container, Col } from 'react-bootstrap';
import { getNestedProp } from '../helper/code';
import MarkDownComponent from './markDown';
import { renderArticleComponents } from '../components';
import { getTranslation } from '../helper/translation';
import { SettingsContext } from './../modules/layout';
import Author from './author'
import Button from './button';
import { propTypes } from 'react-bootstrap/esm/Image';
import SocialShare from './socialShare';
import FormElements from './formElements';

const ArticleComponent = ({ image, caption, date, title, teaser, main, footerTitle, authors, footerText, button, node_locale }) => {
   const { microcopy, articleSocialShareComponent, articleFooterComponent  } = useContext(SettingsContext);

   return (
    <>
        {articleSocialShareComponent && 
        <SocialShare 
            url={articleSocialShareComponent.url}
            displayBreadcrumb={articleSocialShareComponent.displayBreadcrumb}/>}
        <Container fluid>
            <div className='article'>
                <figure>
                    {image && (
                        <>
                            {image?.fluid ? (
                                <GatsbyImage fluid={image.fluid} alt={image?.description || image?.title}></GatsbyImage>
                            ) : (<img src={image?.file?.url} alt={image?.description || image?.title}></img>)}
                        </>
                    )}
                    <figcaption>{caption}</figcaption>
                </figure>
                {title && <Col
                    className={'title mb-3 px-0'}>
                    <h1>{title}</h1>
                </Col>}
                {date && (
                    <time className='date' datetime={date}>{getTranslation('article.date', microcopy)} {getDateFormat(date, node_locale)} {getTranslation('article.time', microcopy)}</time>
                )}
                {teaser && ( <Col
                    className='teaser'>
                        <MarkDownComponent>{getNestedProp(teaser)}</MarkDownComponent>
                </Col>
                )}
                <main>{main && renderArticleComponents(main, node_locale)}</main>
                <div className="authors-container">
                    <h4>{footerTitle}</h4>
                    <div className="authors-grid">
                        {authors?.map((author, index) => {
                            return (
                                <Author {...author} key={index} />
                            );
                        })}
                    </div>
                    {footerText && ( <Col
                        className='footer-text'>
                            <MarkDownComponent>{getNestedProp(footerText)}</MarkDownComponent>
                    </Col>
                    )}
                    {button && 
                    <Button
                        text={button.title}
                        type="secondary"
                        {...button}
                    />}
                </div>
            </div>
        </Container>
        {articleFooterComponent && 
        <FormElements 
            title={articleFooterComponent.title}
            image={articleFooterComponent.image}
            content={articleFooterComponent.content}
            script={articleFooterComponent.script} />}
    </>
  )
}

const getDateFormat = (date, locale) => {
    const _date = new Date(date)
    const monthNames = ["Jan", "Feb", "Mar", "Apri", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const year = _date.getFullYear()
    const month = locale === 'en' ? monthNames[_date.getMonth()] : `${_date.getMonth() + 1}`.padStart(2, '0')
    const day = `${_date.getDate()}`.padStart(2, '0')
    const hour = locale === 'en' ? _date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).toLowerCase() : _date.toLocaleTimeString().slice(0,5)
    return locale === 'en' ? `${day} ${month},${year} ${hour}` : `${day}.${month}.${year} ${hour}`
}

ArticleComponent.propTypes = {
    image: PropTypes.shape(imagePropTypes),
    caption: PropTypes.string,
    date: PropTypes.string,
    title: PropTypes.string,
    showTitle: PropTypes.bool,
    teaser: PropTypes.string,
    main: propTypes.object,
    footerTitle: PropTypes.string,
    authors: PropTypes.object,
    footerText: PropTypes.string,
    button: PropTypes.object,
    node_locale: PropTypes.string,
}

ArticleComponent.defaultProps = {
    image: imageDefaultProps,
    caption: '',
    date: '',
    title: '',
    showTitle: false,
    teaser: '',
    main: null,
    footerTitle: '',
    authors: null,
    footerText: '', 
    button: null, 
    node_locale: ''
};

export default ArticleComponent
