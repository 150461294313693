import GatsbyImage from "gatsby-image";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { createMarkup, getNestedProp } from '../helper/code';
import { cookieExists } from '../helper/cookie';
import MarkDownComponent from './markDown'
import { useInView } from "react-intersection-observer";

const FormElements = ({ title, image, content, script, pageProperties }) => {
    const [ref, inView] = useInView();

    useEffect(() => {
        if (window.MsCrmMkt) {
            window.MsCrmMkt = {};
            const oldScript = document.getElementById("dynamics-form");
            if(oldScript) oldScript.remove();
            if(inView && cookieExists('msd365mkttr')) {
                const formLoader = document.createElement("script");
                formLoader.id = "dynamics-form"
                formLoader.dataset.usercentrics = "Microsoft Dynamics CRM";
                formLoader.src = "https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.72.2001.0";
                formLoader.async = true;
                document.body.appendChild(formLoader);    
            }
        }
    }, [inView])

    return (
        <div className={'form-elements-container'} id="form" ref={ref}>
            <Container fluid>
                <div className="form-elements">
                    <Row className="form-elements-shadow">
                        <Col xl={6} lg={4} md={8} className="form-elements-borderright pt-80 pb-lg-80 px-lg-40 px-md-32">
                            {image && (
                                <GatsbyImage
                                    fixed={image.fixed}
                                    alt={image?.description || image?.title}
                                    className="mb-4" />
                            )}
                            <h3 className="mb-4 mb-lg-5">{title}</h3>
                            <div className="form-elements-description text-justify">
                                <MarkDownComponent>{getNestedProp(content)}</MarkDownComponent>
                            </div>
                        </Col>
                        <Col xl={6} lg={8} className="form-elements-borderright pb-80 pt-40 pt-lg-80 px-lg-40 px-md-32">
                            <div id="page-properties" data-properties={pageProperties?.toString()} dangerouslySetInnerHTML={createMarkup(getNestedProp(script))}></div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )

}

FormElements.propTypes = {
    title: PropTypes.string,
    content: PropTypes.oneOfType(
        [
            PropTypes.shape({
                content: PropTypes.string
            }),
            PropTypes.string
        ]
    ),
    image: PropTypes.object,
    script: PropTypes.oneOfType(
        [
            PropTypes.shape({
                script: PropTypes.string
            }),
            PropTypes.string
        ]
    ),
}
FormElements.defaultProps = {
    title: "",
    content: {
        content: ""
    },
    image: null,
    script: {
        script: ""
    },
}

export default FormElements
