import React from 'react'
import PropTypes from 'prop-types'
import { imageDefaultProps, imagePropTypes } from "../propTypes/image";
import GatsbyImage from "gatsby-image"

const AuthorComponent = ({ image, name, position, company, node_locale }) => {
    return (
        <figure className='author'>
            {image && (
                <>
                    {image?.fixed ? (
                    <GatsbyImage className='author-image' fixed={image.fixed} alt={image?.description || image?.title}></GatsbyImage>
                    ) : (<img className='author-image' src={image?.file?.url} alt={image?.description || image?.title}></img>)}
                </>
            )}
            <figcaption className="author-text">
                <span className="author-name">{name}</span>
                <span className="author-position">{position}</span>
                <span className="author-company">{company}</span>
            </figcaption>                    
        </figure>
  )
}

AuthorComponent.propTypes = {
    image: PropTypes.shape(imagePropTypes),
    name: PropTypes.string,
    position: PropTypes.string,
    company: PropTypes.string
}

AuthorComponent.defaultProps = {
    image: imageDefaultProps,
    name: '',
    position: '',
    company: ''
};

export default AuthorComponent
