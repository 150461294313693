import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useMediaQuery } from 'react-responsive';
import { getTranslation } from '../helper/translation';
import { SettingsContext } from '../modules/layout';
import Button from './button';
import ColorContainer from './colorContainer';
import Card from './galleryCarouselItem';
import { getNestedProp } from '../helper/code';
import MarkDownComponent from './markDown'

const setResponsive = (isOneColumn) => ({
  desktop: {
    breakpoint: { max: 8000, min: 1024 },
    items: isOneColumn ? 1 : 3,
    slidesToSlide: isOneColumn ? 1 : 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 767 },
    items: isOneColumn ? 1 : 2,
    slidesToSlide: isOneColumn ? 1 : 2,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1
  }
});

function CustomRightArrow({ onClick, isEndOfCarousel }) {
  return (
    <button
      onClick={() => onClick()}
      aria-label="Go to next slide"
      className={`react-multiple-carousel__arrow react-multiple-carousel__arrow--right ${isEndOfCarousel ? 'disabled' : ''}`}
    />
  );
}

function CustomLeftArrow({ onClick, isEndOfCarousel }) {
  return (
    <button
      onClick={() => onClick()}
      aria-label="Go to previous slide"
      className={`react-multiple-carousel__arrow react-multiple-carousel__arrow--left ${isEndOfCarousel ? 'disabled' : ''}`}
    />
  );
}

const ButtonGroup = ({ next, previous, carouselState }) => {
  const { currentSlide, slidesToShow, totalItems } = carouselState
  const isLastSlide =  totalItems - slidesToShow === currentSlide
  const isFirstSlide =  currentSlide === 0

  return (
    <div className="carousel-button-group">
      <CustomLeftArrow onClick={() => previous()} isEndOfCarousel={isFirstSlide} />
      <CustomRightArrow onClick={() => next()} isEndOfCarousel={isLastSlide} />
    </div>
  );
};

const GalleryCarousel = ({
  title,
  showTitle,
  headline,
  content,
  isCarousel,
  display1Column, 
  displayJustImage,
  infinite,
  items,
  buttons,
  colorContainer: color
}) => {
  const [isReadMore, setReadMore] = useState(true);
  const [results, setResults] = useState(null);

  const isM = useMediaQuery({ minWidth: 992 });
  const isXS = useMediaQuery({ minWidth: 768 });

  useEffect(() => {
    setResults(isXS ? 6 : 3);
    setReadMore(!isM && items.length > (isXS ? 6 : 3));
  }, [isM, isXS]);
  return (
    <SettingsContext.Consumer>
      {(settings) => {
        const { microcopy } = settings;
        return (
          <ColorContainer color={color}>
            <Container fluid className={'py-60'}>
              <Row>
                <Col xs={12} className={'gallery-carousel-topline mb-4'}>
                  <h4>{headline}</h4>
                </Col>
                {(showTitle != false) && <Col
                  xs={12}
                  md={8}
                  lg={8}
                  className={'gallery-carousel-title mb-3'}
                >
                  <h2>{title}</h2>
                </Col>}
                {content && (
                  <Col
                    xs={12}
                    md={8}
                    lg={8}
                    className={'gallery-carousel-body'}
                  >
                    <MarkDownComponent>{getNestedProp(content)}</MarkDownComponent>
                  </Col>
                )}
              </Row>
              {isCarousel ? (
                <div className="gallery-carousel-container my-5 ">
                  <div className="gallery-carousel-wrapper position-relative mt-5">
                    <Carousel
                      responsive={setResponsive(display1Column)}
                      arrows={false}
                      showDots={true}
                      infinite={infinite}
                      containerClass="gallery-carousel"
                      customLeftArrow={<CustomLeftArrow />}
                      customRightArrow={<CustomRightArrow />}
                      renderButtonGroupOutside={true}
                      customButtonGroup={<ButtonGroup />}
                    >
                      {items &&
                        items.map((card, index) => (
                          <Card
                            {...card}
                            key={index}
                            displayJustImage={displayJustImage}
                            display1Column={display1Column}
                          />
                        ))}
                    </Carousel>
                  </div>
                </div>
              ) : (
                <div className="gallery-carousel-container my-40">
                  <div className="gallery-grid">
                    <Row>
                      {isReadMore
                        ? items &&
                          items.map((card, index) => {
                            if (index < results || results == null) {
                              return (
                                <Col key={index}   {...(display1Column ? { xs: 12 } : { xs: 12, md: 6, lg: 4 })} >
                                  <Card {...card} key={index} displayJustImage={displayJustImage} />
                                </Col>
                              );
                            }
                          })
                        : items &&
                          items.map((card, index) => (
                            <Col key={index} {...(display1Column ? { xs: 12 } : { xs: 12, md: 6, lg: 4 })} >
                              <Card {...card} key={index} displayJustImage={displayJustImage} />
                            </Col>
                          ))}
                    </Row>
                    {isReadMore && (
                      <div className={'text-center'}>
                        <Button
                          text={getTranslation('label.readMore', microcopy)}
                          type="secondary"
                          onClick={() => setReadMore(false)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              <Row>
                {buttons &&
                  buttons?.map((button) => (
                    <Col xs={12} md={6} lg={4} xl={4}>
                      <Button
                        text={button.title}
                        {...button}
                        className={'w-100 mb-4 d-flex justify-content-center'}
                      />
                    </Col>
                  ))}
              </Row>
            </Container>
          </ColorContainer>
        );
      }}
    </SettingsContext.Consumer>
  );
};

GalleryCarousel.propTypes = {
  title: PropTypes.string,
  headline: PropTypes.string,
  isCarousel: PropTypes.bool,
  display1Column: PropTypes.bool,
  displayJustImage: PropTypes.bool,
  infinite: PropTypes.bool,
  content: PropTypes.shape({
    content: PropTypes.string
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.object,
      title: PropTypes.string,
      content: PropTypes.object,
      link: PropTypes.object
    })
  ),
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      type: PropTypes.string
    })
  )
};
GalleryCarousel.defaultProps = {
  title: '',
  headline: '',
  isCarousel: false,
  display1Column: false,
  displayJustImage: false,
  infinite: false,
  content: {
    content: ''
  },
  items: [
    {
      image: {},
      title: '',
      content: {},
      link: {}
    }
  ],
  buttons: []
};

export default GalleryCarousel;
