import PropTypes from 'prop-types';
import React from 'react';
import Link from '../../link';

const MenuLink = ({ item }) => {

  return (
    <Link to={{ target: { ...item.page, slug: item?.page.fullSlug } }} className="nav-menu-link">
      {item?.icon && (
        <img
          className="mr-2"
          src={item?.icon?.file?.url}
          alt={item?.icon?.description || item?.icon?.title}
        />
      )}
      {item?.name}
    </Link>
  );
};

MenuLink.propTypes = {
  item: PropTypes.object,
  locale: PropTypes.any
};
MenuLink.defaultProps = {};

export default MenuLink;
