import { get } from "lodash";

export const createMarkup = (str) => {
    return { __html: str };
}

//if input is an object returns the appropiate key if is not returns the field
export const getNestedProp = (input) => {
  if (!input) return '';

  if (typeof input !== 'object') return input;

  const nestedProp = get(input, Object.keys(input)[0], input);

  return nestedProp;
};