import { faLinkedin, faFacebook, faXingSquare, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from '../components/button';
import { getTranslation } from '../helper/translation';
import { LinkRenderer } from '../helper/linkRenderer'
import LanguageSwitch from './languageSwitch';
import { SettingsContext } from './layout';
import { useMediaQuery } from 'react-responsive';
import Link from './link';

const Footer = () => {
    const { microcopy, node_locale, footerLogo, megaMenu} = useContext(SettingsContext);
    const [isMinWidthMedium, setIsMinWidthMedium] = useState(true);
    const mediaQuery = useMediaQuery({ minWidth: 1024 });
    const locale = node_locale !== 'de' ? `/${node_locale}` : '';

    useEffect(() => {
        if(mediaQuery !== isMinWidthMedium){
          setIsMinWidthMedium(mediaQuery);
        }
      }, [mediaQuery])

    return (
        <footer className='pt-5'>
            <Row className={'p-0 m-0 social-icon-row'}>
                <Container
                    fluid
                >
                    <Row className={'py-3 d-flex align-items-center'}>
                        <Col
                            className={'d-flex flex-column mb-3 px-5'}
                            xs={12}
                            lg={isMinWidthMedium ? 8 : 12}
                        >
                            <Link href="/" className="navbar-brand">
                                <img src={footerLogo?.file?.url} alt="Vier logo" className="vier-logo"/>
                            </Link>
                            <Row>
                                {megaMenu?.map((column) => {
                                    return (
                                     <Col xs={6} sm={isMinWidthMedium ? 3 : 6}>
                                        <h5 className='my-3'><a href={locale + `/${column.page?.fullSlug}/`}>{column.name}</a></h5>
                                        <ul className="nav flex-column">
                                            {column.items.map((element) => {
                                                return element.links && element.links.map((item) => {
                                                    return ((item.show && item.page?.localizedPage) && 
                                                        <li className="nav-item mb-1">
                                                            {LinkRenderer({href: (item.page?.url || locale + `/${item.page?.fullSlug}`), class: "nav-link p-0", children: item.name})}
                                                        </li>
                                                    )
                                                })
                                            })}
                                        </ul>
                                    </Col>)
                                })}
                            </Row>
                        </Col>
                        <Col
                            xs={12}
                            lg={isMinWidthMedium ? 4 : 12}
                            className={'d-flex flex-column align-items-center mt-auto footer-navigation'}
                        >
                            <Row className={'d-flex mb-4 footer-navigation-row'}>
                                <Col
                                    className={'d-flex mt-4 mt-lg-0 social-media-links'}
                                    xs={12}
                                    lg={isMinWidthMedium ? 4 : 12}
                                >
                                    <Link to={{ url: getTranslation('footer.linkedInLink', microcopy) }}>
                                        <FontAwesomeIcon className={'social-icon'} icon={faLinkedin} />
                                    </Link>
                                    <Link to={{ url: getTranslation('footer.xingLink', microcopy) }}>
                                        <FontAwesomeIcon className={'social-icon'} icon={faXingSquare} />
                                    </Link>
                                    <Link to={{ url: getTranslation('footer.facebookLink', microcopy) }}>
                                        <FontAwesomeIcon className={'social-icon'} icon={faFacebook} />
                                    </Link>
                                    <Link to={{ url: getTranslation('footer.youtubeLink', microcopy) }}>
                                        <FontAwesomeIcon className={'social-icon'} icon={faYoutube} />
                                    </Link>
                                </Col>
                            </Row>
                            <Row className={'d-flex align-items-center footer-navigation-row'}>
                                <Button className={'py-1 px-3'} link={getTranslation('login.link', microcopy)} text={getTranslation('login.text', microcopy)} type={'tertiary'} />
                                <Link to={getTranslation('contact.link', microcopy)}>{getTranslation('footer.contact', microcopy)}</Link>
                                <LanguageSwitch />
                            </Row>
                        </Col>
                    </Row>
                </Container>

            </Row>
            <Container
                fluid
            >
                <Row className={'pb-sm-3 py-3 d-flex align-items-center'}>
                    <Col
                        className={'col-auto text-left mt-0 mr-auto order-2 order-sm-1'}
                    >
                        <span
                            className={'copyright'}
                        >
                            {getTranslation('footer.copyright', microcopy)}
                        </span>
                    </Col>
                    <Col
                        className={'col-auto d-flex footer-sub-navigation align-content-between order-1 order-sm-2'}
                    >
                        <Link to={getTranslation('footer.imprintLink', microcopy)}>{getTranslation('footer.imprint', microcopy)}</Link>
                        <Link to={getTranslation('footer.agbLink', microcopy)}>{getTranslation('footer.agb', microcopy)}</Link>
                        <Link to={getTranslation('footer.dataProtectionLink', microcopy)}>{getTranslation('footer.dataProtection', microcopy)}</Link>   
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer