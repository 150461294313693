import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { getNestedProp } from '../helper/code';
import Link from '../modules/link';
import MarkDownComponent from './markDown';


const Card = ({ image, title, content, link, color, isFlipped }) => {
  const [flipped, setFlipped] = useState(isFlipped);

  return (
    <Col
      xs={12}
      sm={6}
      md={6}
      lg={4}
      xl={3}
      onClick={() => setFlipped(!flipped)}
      onMouseEnter={() => setFlipped(!flipped)}
      onMouseLeave={() => setFlipped(!flipped)}
      className='card-container'
    >
      <div className={`flip-card`}>
        <div className={`flip-card-inner ${flipped ? 'flipped' : ''}`}>
          {!flipped ? (
            <>
              {image?.fluid ? (
                <BackgroundImage className={'front'} fluid={image?.fluid} alt={image?.description || image?.title} />
              ) : (
                <img className={'front'} src={image?.file?.url} alt={image?.description || image?.title} />
              )}
            </>
          ) : (
            <div className={`back px-4 py-5 ${color ? `bg-${color}` : ''}`}>
              <div className={'card__title'}>
                <h5 className={'mb-4'}>{title}</h5>
              </div>
              <div className={'card__text'}>
                <div className="mb-4">
                  <MarkDownComponent>{getNestedProp(content)}</MarkDownComponent>
                </div>
              </div>
              {link && (
                <div className={'card__link'}>
                  <Link to={link}>{link?.title}</Link>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Col>
  );
};

const FlipCardsComponent = ({ title, doNotShowTitle, headline, content, cards }) => {
  return (
    <Container fluid className={'flip-cards py-60'}>
      <Row>
          {headline && (
            <Col xs={12} className='mb-4'>
              <h4>{headline}</h4>
            </Col>
          )}
          {!doNotShowTitle && <Col
            xs={12}
            md={8}
            lg={8}
            className={'mb-3 flip-cards-title'}
          >
            {title && (<h2>{title}</h2>)}
          </Col>}
          {content && (
            <Col
              xs={12}
              md={8}
              lg={8}
              className={'flip-cards-body'}
            >
              <MarkDownComponent>{getNestedProp(content)}</MarkDownComponent>
            </Col>
          )}
        </Row>
      <div>
        <Row>
          {cards && cards.map((card, index) => <Card key={index} {...card} />)}
        </Row>
      </div>
    </Container>
  );
};

export const fragment = graphql`
  fragment flipCards on ContentfulComponentFlipCardContainer {
    title
    doNotShowTitle
    headline
    content {
      content
    }
    cards {
      ... on ContentfulComponentFlipCard {
        title
        content {
          content
        }
        isFlipped
        link {
          ...link
        }
        image {
          file {
            url
          }
          title
          fixed(width: 150, quality: 100, toFormat: PNG) {
            ...imageFixed
          }
          fluid(quality: 100) {
            ...imageFluid
          }
          description
        }
        color
      }
    }
  }
`;

FlipCardsComponent.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string,
  content: PropTypes.shape({
    content: PropTypes.string
  }),
  link: PropTypes.object,
  isFlipped: PropTypes.bool
};
FlipCardsComponent.defaultProps = {
  image: {},
  title: '',
  content: {
    content: ''
  },
  link: {},
  isFlipped: false
};

export default FlipCardsComponent;