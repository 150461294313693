import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Container } from 'react-bootstrap';
import { createMarkup, getNestedProp } from '../helper/code';

const CodeComponent = ({ code }) => {
  return (
    <Container fluid>
      <div
        className="codeContainer"
        dangerouslySetInnerHTML={createMarkup(getNestedProp(code))}></div>
    </Container>
  );
};

CodeComponent.propTypes = {
  code: PropTypes.oneOfType([
    PropTypes.shape({
      code: PropTypes.string
    }),
    PropTypes.string
  ])
};

CodeComponent.defaultProps = {
  code: {
    code: ''
  }
};

export default CodeComponent;