import PropTypes from "prop-types";
import React, {useContext} from "react";
import { isInternalLink, addAnchorToInternalLink } from "../helper/linkRenderer";
import { getLocalizedLink } from "../helper/translation";
import { SettingsContext } from "../modules/layout";

const Link = ({ to, className, children,}) => {
    const { node_locale} = useContext(SettingsContext);

    const getInternalLink = () => {
        const targetIsSlug = typeof to === "string";
        const localizedLink = getLocalizedLink(targetIsSlug ? to : "/" + to?.target?.fullSlug, node_locale)
        const linkWithTrailingSlash = setTrailingSlash(localizedLink)

        return addAnchorToInternalLink(to, linkWithTrailingSlash)
    }

    return (
            <a 
                href={to.hasOwnProperty('url') ? to.url : getInternalLink()}
                className={className}
                target={`${isInternalLink(to.url) ? '_self' : '_blank'}`} 
                rel={`${isInternalLink(to.url) ? '' : 'noopener noreferrer'}`}
            >
                {to.linkText}
                {children}
            </a>
    )
}

const setTrailingSlash = (str) => {
    if (!str) return "";
  
    if (str.slice(-1) === '/') return str;
  
    return str + '/';
};

Link.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

Link.defaultProps = {
    className: '',
    text: '',
    to: '/'
}

export default Link;