import { faFacebookF, faLinkedin, faTwitter, faXingSquare } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { getTranslation } from "../helper/translation";
import Icon from "../modules/icon";
import { SettingsContext } from "../modules/layout";
import Link from "../modules/link";

const SocialShare = ({ url, displayBreadcrumb }) => {
    const [transparent, setTransparent] = useState(false);
    const socialShareRef = useRef();

    useEffect(() => {
        if(socialShareRef.current) {
            const {offsetTop} = socialShareRef.current;
            setTransparent(offsetTop < 200);
        }
    }, [])

    const shareIcons = [
        {
            icon: faFacebookF,
            url: `https://www.facebook.com/sharer/sharer.php?u=`
        },
        {
            icon: faTwitter,
            url: `https://www.twitter.com/share?url=`
        },
        {
            icon: faLinkedin,
            url: `https://www.linkedin.com/sharing/share-offsite/?url=`
        },
        {
            icon: faXingSquare,
            url: `https://www.xing.com/spi/shares/new?url=`
        },
    ]
    return (
        <SettingsContext.Consumer>
            {settings => (
                <div
                    ref={socialShareRef}
                    className={`social-share w-100 ${transparent ? 'transparent' : ''}`}
                >
                    <Container
                        fluid
                    >
                        <Row>
                            {displayBreadcrumb && (
                                <Col
                                    xs={12}
                                    sm={6}
                                >
                                    <nav className={'breadcrumb mb-4 mb-sm-0'}>
                                        <ul>
                                            {settings?.breadcrumbs?.map(breadcrumb => (
                                                <li>
                                                    <Link
                                                        to={`/${breadcrumb.slug}`}>
                                                        {breadcrumb.title}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>
                                </Col>
                            )}
                            <Col className={'text-sm-right d-flex justify-content-sm-end align-items-center'}>
                                {shareIcons.map((icon, key) => (
                                    <Link
                                        to={{ url: icon.url + url }}
                                        key={key}
                                        rel= "nofollow noopener noreferrer"
                                    >
                                        <FontAwesomeIcon
                                            className={'social-share-icon'}
                                            size={'1x'}
                                            icon={icon.icon}
                                        />
                                    </Link>
                                ))}
                                <OverlayTrigger
                                    trigger="click" placement="bottom" overlay={(
                                        <Tooltip id="popover-basic">
                                            {getTranslation('socialShare.copied', settings.microcopy)}
                                        </Tooltip>
                                    )}
                                >
                                    <Icon
                                        name={'share'}
                                        className={'social-share-icon share-icon'}
                                        hover
                                        onClick={() => { navigator.clipboard.writeText(window.location.href) }}
                                    />
                                </OverlayTrigger>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )}
        </SettingsContext.Consumer>

    )
}

SocialShare.propTypes = {
    url: PropTypes.string,
    displayBreadcrumb: PropTypes.bool
}

SocialShare.defaultProps = {
    url: "",
    displayBreadcrumb: false
}

export default SocialShare