import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { get } from 'lodash';
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import slugify from 'slugify';
import { LinkRenderer } from "../helper/linkRenderer";
import Icon from "../modules/icon";
import { getNestedProp } from '../helper/code';
import MarkDownComponent from './markDown';

const options = {
    renderMark: {
        [MARKS.BOLD]: text => <b>{text}</b>,
        [MARKS.QUOTE]: quote => <blockquote className='quote'>{quote}</blockquote>
    },
    renderNode: {
        [BLOCKS.HEADING_3]: (node, children) =>  <h3 id={slugify(node.content[0].value)}>{children}</h3>,
        [INLINES.HYPERLINK]: (node) => {  
            return LinkRenderer({ href: node.data.uri, children: node.content[0].value })
        },
        [BLOCKS.PARAGRAPH]: (node, children) => {
            //To avoid adding br in OL and UL that comes with empty text nodes.
            if(children.length > 1) {
                children = children.filter(child => child.type !== 'br')
            }
            return <p>{children}</p>
        }
    },
    renderText: text => {
        if (!text) {
            return <br />;
        }
        return text.split('\n').reduce((children, textSegment, index) => {
            return [...children, index > 0 && <br key={index} />, textSegment];
        }, []);
    }
};

const RichTextComponent = ({ title, showTitle, content, richTextHeadline }) => {
    const parsed = content && content.raw ? JSON.parse(content.raw) : content;
    const headlines = parsed.content.filter(node => node.nodeType === 'heading-3');

    return (
        <Container fluid className={'rich-text'}>
            {richTextHeadline &&
                <h4 className="linebreak">
                    <MarkDownComponent>{getNestedProp(richTextHeadline)}</MarkDownComponent>
                </h4>
            }
            {(showTitle != false) && (<h2>{title}</h2>)}
            <Row>
                {
                    !!headlines.length ? (
                        <Col md={5} className={'contents'}>
                            {headlines.map(headline => (
                                <div className={'d-flex justify-content-start align-items-center mb-3'}>
                                    <Icon name={'arrowLineDown'} className={'mr-2'} />
                                    <a href={`#${slugify(get(headline, 'content[0].value'))}`}>{get(headline, 'content[0].value')}</a>
                                </div>
                            ))}
                        </Col>
                    ) : ""
                }
                <Col md={headlines.length ? 7 : 12} className={'mb-40'}>
                    {documentToReactComponents(parsed, options)}
                </Col>
            </Row>
        </Container>
    )
}

RichTextComponent.propTypes = {
    title: PropTypes.string,
    content: PropTypes.shape({
        raw: PropTypes.string
    })
}

RichTextComponent.defaultProps = {
    title: "",
    content: {
        raw: ""
    }
}

export default RichTextComponent