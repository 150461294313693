import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import PropTypes from 'prop-types';
import React from "react";
import { Col, Container, Row } from 'react-bootstrap';
import Button from "./button";

const LiveStream = ({ video, chat, textLeft, textRight, buttons }) => {
    const textLeftParsed = textLeft && textLeft.raw ? JSON.parse(textLeft.raw) : textLeft;
    const textRightParsed = textRight && textRight.raw ? JSON.parse(textRight.raw) : textRight;

    return (
        <Container fluid className={'live-stream'}>
            <Row>
                <Col
                    xs={12}
                    md={8}
                >
                    {video && (
                        <div className={'live-stream-video'}>
                            <iframe
                                src={video}
                                frameborder="0"
                                allow="autoplay; fullscreen; picture-in-picture"
                                allowfullscreen
                            />
                        </div>
                    )}
                </Col>
                <Col
                    xs={12}
                    md={4}
                >
                    {chat && (
                        <div className={'live-stream-chat'}>
                            <iframe
                                src={chat}
                                frameborder="0"
                            />
                        </div>
                    )}
                </Col>
            </Row>
            <Row className={'py-4'}>
                <Col
                    xs={12}
                    sm={6}
                >
                    {documentToReactComponents(textLeftParsed)}
                    {buttons[0] && (
                        <Button
                            text={buttons[0].title}
                            type={buttons[0].type}
                        />
                    )}
                </Col>
                <Col
                    xs={12}
                    sm={6}
                >
                    {documentToReactComponents(textRightParsed)}
                    {buttons[1] && (
                        <Button
                            text={buttons[1].title}
                            type={buttons[1].type}
                        />
                    )}
                </Col>
            </Row>
        </Container>
    )
}

LiveStream.propTypes = {
    title: PropTypes.string,
    video: PropTypes.string,
    chat: PropTypes.string,
    textLeft: PropTypes.string,
    textRight: PropTypes.string,
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            type: PropTypes.string
        })
    )
}
LiveStream.defaultProps = {
    title: "",
    video: "",
    chat: "",
    textLeft: "",
    textRight: "",
    buttons: []
}

export default LiveStream