import React, { useEffect, useState } from "react";
import MarketingCookie from "./marketingCookie";


const CookieConsentBanner = () => {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        if (typeof window !== "undefined") {
            setIsClient(true);
        }
    }, []);


    return isClient ? (
        <MarketingCookie/>
    ) : ""
}

CookieConsentBanner.propTypes = {
}

CookieConsentBanner.defaultProps = {
}

export default CookieConsentBanner